import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";

const EarlyAccessGateDone = ({ signupDoc }) => {

  return (
    <div className="signup-page">
      <div className="d-flex mx-auto mw-1440">
        <div className="signup-info-container">
        <div className="signup-logo-container d-none d-lg-block mx-auto">
            <Link to="/">
              <img src={signupDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>            
          </div>
          <div className="signup-info text-center text-lg-start">
            <h6>{signupDoc.data?.title[0].text}</h6>
            <RichText render={signupDoc.data?.description} />
          </div>
        </div>
        <div className="signup-form-container">
          <div className="signup-logo-container text-center d-lg-none">
            <Link to="/">
              <img src="https://image-hosting-opalvest.s3.us-east-1.amazonaws.com/opalvest-login-2x.png" alt="Brand Logo"/>
            </Link>
          </div>
          <div className="signup-form">
            <div className="signup-text text-center text-lg-start">
              <h2>Early Access & Partnerships</h2>
            </div>
            <div className="signup-text-description text-center text-lg-start">
              <p>Opalvest will soon offer its inaugural Opals to select investors and wealth managers.</p>
              <p>For early access or partnership inquiries, please share your information and we’ll be in touch.</p>
            </div>

            <div className="early-access-gate-done">
              <h5>Thank you for registering!</h5>
              <p>A member of our team will be in touch soon.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default EarlyAccessGateDone;
